import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import BecomeACoachComponent from "../Front/Pages/Become-a-coach";
import HomeComponent from "../Front/Pages/Home/index";
import LogoutUserHomeComponent from "../Front/Pages/Home/logoutUserHomePage";
import BrowseCoachComponent from "../Front/Pages/Browse-coach";
import BrowseCoachListViewComponent from "../Front/Pages/Browse-coach/listView";
import BrowseCoachGridViewComponent from "../Front/Pages/Browse-coach/gridView";
import CoachDetailsComponent from "../Front/Pages/Browse-coach/coachDetails";
import BlogComponent from "../Front/Pages/Blog";
import UserLayoutComponent from "../User/userLayout";
import UserDashboardComponent from "../User/Pages/Dashboard";
import UserProfileComponent from "../User/Pages/Profile";
import UserSettingComponent from "../User/Pages/Setting";
import UserActivitiesComponent from "../User/Pages/Activities";
import UserMyCoachComponent from "../User/Pages/MyCoach";
import UserScheduleSessionComponent from "../User/Pages/ScheduleSession";
// import UserNotificationComponent from "../User/Pages/Notification";

import UserMessagesComponent from "../User/Pages/Messages";
import HomeLayoutComponent from "../Front/HomeLayout";
import ActivityStreamComponent from "../Front/Pages/Activity-stream";
import SingleFeedComponent from "../Front/Pages/Activity-stream/singleSocialFeed";
import SingleShortComponent from "../Front/Pages/Activity-stream/singleShortVideo";

import CoachLayoutComponent from "../Coach/coachLayout";
import CoachActivitiesComponent from "../Coach/Pages/Activities";
import CoachSettingComponent from "../Coach/Pages/Setting";
import CoachMessagesComponent from "../Coach/Pages/Messages";

import CoachCalendarComponent from "../Coach/Pages/Calendar";
import CoachBookingCalendarComponent from "../Coach/Pages/Calendar/booking";

import CoachProfilePersonalInformationComponent from "../Coach/Pages/Profile/ProfilePersonal";
import CoachProfileAboutYourSelfComponent from "../Coach/Pages/Profile/AboutYourSelf";
import CoachProfileCoachingDetailsComponent from "../Coach/Pages/Profile/CoachingDetails";
import CoachProfileProfessionalDetailsComponent from "../Coach/Pages/Profile/ProfessionalDetails";
import CoachMyProfileComponent from "../Coach/Pages/Profile";
import TermsServicesComponent from "../Front/Pages/TermsServices";
import PrivacyPolicyComponent from "../Front/Pages/PrivacyPolicy";
import UpdatepasswordComponent from "../Front/Pages/Updatepassword";
import ContactUsComponent from "../Front/Pages/ContactUs";
import ProtectedRoute from './ProtectedRoutes'
import NotFound from '../Front/Pages/NotFound'
import SubmitForm from '../SubmitForm'

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CoachProfilePreview from "../Coach/Pages/Profile/ProfilePreview";
import LatestBlogComponent from "../Front/Pages/Blog/latest_blogs";
import BlogDetailsComponent from "../Front/Pages/Blog/blog_details";
import CoachBlogComponent from "../Coach/Pages/Blogs";
import CreateBlogComponent from "../Coach/Pages/Blogs/create";

import CanvasWhiteBoardComponent from "./../Front/Pages/agora/CanvasWhiteBoard";
import CreateLiveSession from "../Coach/Pages/Activities/createLiveSession";

import { toast } from "react-toastify";
import CoachEarningComponent from "../Coach/Pages/Earnings";
import AboutComponent from "../Front/Pages/About";
import AddAvailabilitySlot from "../Coach/Pages/Bookings/AddAvailabilitySlot";
import UserUpdateAvailabilityComponent from "../User/Pages/Booking"
import MyProgramsComponents from "../User/Pages/Programs";
import ScheduleSessionComponent from "../Front/Pages/Schedule-session";
import CoachEventCalendarComponent from "../Coach/Pages/Event";

const Root = () => {
    const [modalOpen, setModalOpen] = useState(true);
    const [password, setPassword] = useState('');

    const getData = () => {
        const storedData = localStorage.getItem('websiteDisplay');
        console.log(storedData);
        if (storedData === 'true') {
            setModalOpen(false)
        }
    }

    useEffect(() => {
        getData();
    }, []);


    const openSite = (e) => {
        e.preventDefault();
        if (password === "Admin@123") {
            localStorage.setItem("websiteDisplay", 'true');
            setModalOpen(false)
            toast.success('Login successfully');
        } else {
            toast.error('Incorrect password');
        }
    }
    const handleInputChange = (value) => {
        // setInputValue(value);
    };
    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                z-index='111111'
            />
            {
                modalOpen ?
                    <>
                        {/* <Navbar key='lg' expand='lg' sticky="top">
                            <Container className="user_container">
                                <Col lg="auto">
                                    <NavLink className="navbar-brand" to="/">
                                        <img src={logo} />
                                    </NavLink>
                                </Col>
                                <Col lg="auto">
                                    <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-lg`} />
                                    <Navbar.Offcanvas id={`offcanvasNavbar-expand-lg`} aria-labelledby={`offcanvasNavbarLabel-expand-lg`} placement="end" >
                                        <Offcanvas.Header closeButton>
                                            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`}>
                                                <NavLink to="">
                                                    <img src={logo} />
                                                </NavLink>
                                            </Offcanvas.Title>
                                        </Offcanvas.Header>
                                        <Offcanvas.Body>
                                            <Nav className="justify-content-end flex-grow-1 align-items-center">
                                                <NavLink className="nav-link" to="">Browse Coach</NavLink>
                                                <NavLink className="nav-link" to="">Activity Stream</NavLink>
                                                <NavLink className="nav-link" to="">Become  a Coach</NavLink>
                                                <NavLink className="nav-link login_btn">Login</NavLink>
                                            </Nav>
                                        </Offcanvas.Body>
                                    </Navbar.Offcanvas>
                                </Col>
                            </Container>
                        </Navbar>
                        <section className="home_banner">
                            <div className="video-container">
                                <video
                                    className="fullscreen-video"
                                    autoPlay
                                    loop
                                    muted
                                    playsInline
                                    preload="auto"
                                >
                                    <source src={herovideo} type="video/mp4" />
                                    <source src="movie.ogg" type="video/ogg" />
                                    Your browser does not support the video tag.
                                </video>
                                <Container className="container_bg container position-absolute z-3 position-absolute top-50 start-50 translate-middle">
                                    <Row className="justify-content-center">
                                        <Col lg="8">
                                            <h2 className="fs-50 fw-600">Your Need is Unique, So Your Coach Should Be Too!</h2>
                                        </Col>
                                    </Row>
                                    <Row className="justify-content-center g-3">
                                        <Col lg="8">
                                            <Row className="justify-content-between align-items-center g-3 mt-2">
                                                <Col lg="12">
                                                    <div className="white_bg_msg_strip">
                                                        <InputGroup className="justify-content-between" style={{ flexWrap: 'nowrap' }}>
                                                            <SearchEverythingComponent  onInputChange={handleInputChange} />
                                                            <Button
                                                                variant="outline-secondary"
                                                                id="button-addon1"
                                                                className="bg-transparent"
                                                            >
                                                                <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <g clip-path="url(#clip0_6469_6028)">
                                                                        <path d="M25.3126 10.0514L5.32641 0.650398C3.96141 -0.0111019 2.35724 0.215231 1.23141 1.22906C0.10091 2.2464 -0.292257 3.8284 0.229243 5.2564C0.257243 5.33107 5.01958 14.2794 5.01958 14.2794C5.01958 14.2794 0.347076 23.2207 0.320243 23.2942C-0.198924 24.7246 0.197743 26.3042 1.32941 27.3192C2.03058 27.9469 2.91374 28.2712 3.80391 28.2712C4.35224 28.2712 4.90174 28.1487 5.41274 27.8967L25.3137 18.5016C26.9716 17.7234 28.0006 16.1029 27.9994 14.2724C27.9971 12.4419 26.9669 10.8237 25.3126 10.0502V10.0514ZM3.50058 3.98706C3.49591 3.94273 3.50874 3.88907 3.57291 3.8319C3.67558 3.73973 3.75608 3.77706 3.80041 3.8004C3.80858 3.80506 3.81791 3.80856 3.82491 3.81323L22.3446 12.5236H8.04941L3.50058 3.98706ZM3.89258 24.7456C3.85058 24.7677 3.76891 24.8074 3.66741 24.7152C3.60208 24.6569 3.59041 24.6009 3.59508 24.5566L8.05991 16.0236H22.3702L3.89374 24.7467L3.89258 24.7456Z" fill="white" />
                                                                    </g>
                                                                    <defs>
                                                                        <clipPath id="clip0_6469_6028">
                                                                            <rect width="28" height="28" fill="white" transform="translate(0 0.271484)" />
                                                                        </clipPath>
                                                                    </defs>
                                                                </svg>
                                                            </Button>
                                                        </InputGroup>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </section>
                        <section className="banner_total_counter z-2 position-relative">
                            <Container className="d-none d-sm-block">
                                <Row className="g-sm-1 justify-content-center justify-content-lg-between">
                                    <Col className="">
                                        <div className="text-center">
                                            <h3>10,000+</h3>
                                            <h4>Session Conducted</h4>
                                        </div>
                                    </Col>
                                    <Col className="">
                                        <div className="text-center">
                                            <h3>40,000+</h3>
                                            <h4>Users Enrolled</h4>
                                        </div>
                                    </Col>
                                    <Col className="">
                                        <div className="text-center">
                                            <h3>1000+</h3>
                                            <h4>Experienced Coaches</h4>
                                        </div>
                                    </Col>
                                    <Col className="">
                                        <div className="text-center">
                                            <h3>200+</h3>
                                            <h4>Became Coaches</h4>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                            <Container className="d-block d-sm-none">
                                <OwlCarousel
                                    className="owl-theme"
                                    autoplay
                                    loop
                                    margin={15}
                                    items="1"
                                    dots={false}
                                >
                                    <div className="item">
                                        <div className="text-center">
                                            <h3>10,000+</h3>
                                            <h4>session conducted</h4>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="text-center">
                                            <h3>40,000+</h3>
                                            <h4>users enrolled</h4>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="text-center">
                                            <h3>1000+</h3>
                                            <h4>experienced coaches</h4>
                                        </div>
                                    </div>
                                </OwlCarousel>
                            </Container>
                        </section>
                        <Modal show={modalOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered className='shortvideo-model h-90 my_light_modal default-popup' >
                            <div className="brand_bg_color1 my_light_modal">
                                <div className="ls_modal_conatiner">
                                    <div class="hdng_a text-start">
                                        <h6 class="text-uppercase">Login</h6>
                                        <h3 class="my-3 fw-700" style={{ fontFamily: 'volkhov' }}>Welcome to Our Site!</h3>
                                    </div>
                                    <form class="px-lg-4">
                                        <div class="mb-3 mt-3">
                                            <input name="password" placeholder="Your Password" type="password" id="password" class="form-control" value={password} onChange={(e) => (setPassword(e.target.value))} />
                                            <div class="invalid-feedback">Password is required</div>
                                        </div>
                                        <div>
                                            <button onClick={(e) => openSite(e)} className="btn btn-success">Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </Modal> */}
                        <Routes>
                            <Route path="/" element={<HomeLayoutComponent />}>
                                <Route index element={<HomeComponent />} />
                            </Route>
                            <Route path="submit" element={<SubmitForm />} />
                            <Route path="*" element={<NotFound />} />
                        </Routes>
                        <div className="position-fixed bottom-0 bg-black w-100 text-center fs-12 z-3 p-2">
                            <p className="m-0 text-white">Beta Version</p>
                        </div>
                    </>
                    :
                    <>
                        <Routes>
                            <Route path="/" element={<HomeLayoutComponent />}>
                                <Route index element={<HomeComponent />} />
                                <Route exact path="become-a-coach" element={<BecomeACoachComponent />} />
                                <Route path="browse-coach" element={<BrowseCoachComponent />} >
                                    <Route index path="list-view" element={<BrowseCoachListViewComponent />} />
                                    <Route path="grid-view" element={<BrowseCoachGridViewComponent />} />
                                    <Route className="sdf" path="coach-details/:id" element={<CoachDetailsComponent />} />
                                </Route>
                                <Route exact path="blogs" element={<BlogComponent />} />
                                <Route exact path="latest-blogs" element={<LatestBlogComponent />} />
                                <Route exact path="blog-details/:id" element={<BlogDetailsComponent />} />
                                <Route exact path="activity-stream" element={<ActivityStreamComponent />} />
                                <Route exact path="single-feed/:id" element={<SingleFeedComponent />} />
                                <Route exact path="single-short/:id" element={<SingleShortComponent />} />
                                <Route exact path="contact-us" element={<ContactUsComponent />} />
                                <Route exact path="terms-of-services" element={<TermsServicesComponent />} />
                                <Route exact path="privacy-policy" element={<PrivacyPolicyComponent />} />
                                <Route exact path="updatepassword/:token" element={<UpdatepasswordComponent />} />
                                <Route exact path="about" element={<AboutComponent/> } />
                                <Route exact path="schedule-session" element={<ScheduleSessionComponent  /> } />
                            </Route>

                            <Route path="user" element={<ProtectedRoute auth={["user"]} ><UserLayoutComponent /></ProtectedRoute>}>
                                <Route path="dashboard" element={<ProtectedRoute auth={["user"]} ><UserDashboardComponent /></ProtectedRoute>} />
                                <Route path="profile" element={<ProtectedRoute auth={["user"]} ><UserProfileComponent /></ProtectedRoute>} />
                                <Route path="edit-profile" element={<ProtectedRoute auth={["user"]} ><UserProfileComponent /></ProtectedRoute>} />
                                <Route path="messages" element={<ProtectedRoute auth={["user"]} ><UserMessagesComponent /></ProtectedRoute>} />
                                <Route path="setting" element={<ProtectedRoute auth={["user"]} ><UserSettingComponent /></ProtectedRoute>} />
                                <Route path="activities" element={<ProtectedRoute auth={["user"]} ><UserActivitiesComponent /></ProtectedRoute>} />
                                <Route path="my-coach" element={<ProtectedRoute auth={["user"]} ><UserMyCoachComponent /></ProtectedRoute>} />
                                <Route path="schedule-session" element={<ProtectedRoute auth={["user"]} ><UserScheduleSessionComponent /></ProtectedRoute>} />
                                <Route path="update-availability" element={<ProtectedRoute auth={["user"]} ><UserUpdateAvailabilityComponent /></ProtectedRoute>} />
                                <Route path="my-programs" element={<ProtectedRoute auth={["user"]} ><MyProgramsComponents /></ProtectedRoute>} />
                                
                            </Route>

                            <Route path="coach" element={<ProtectedRoute auth={["coach"]} ><CoachLayoutComponent /></ProtectedRoute>}>
                                <Route index path="profile" element={<ProtectedRoute auth={["coach"]} ><CoachMyProfileComponent /></ProtectedRoute>} />
                                <Route index path="preview" element={<ProtectedRoute auth={["coach"]} > <CoachProfilePreview /> </ProtectedRoute>} />

                                <Route index element={<CoachProfilePersonalInformationComponent />} />
                                {/* <Route index path="personal-information"  element={<CoachProfilePersonalInformationComponent />} /> */}
                                <Route exact path="about-yourself" element={<ProtectedRoute auth={["coach"]} ><CoachProfileAboutYourSelfComponent /></ProtectedRoute>} />
                                <Route exact path="coaching-details" element={<ProtectedRoute auth={["coach"]} ><CoachProfileCoachingDetailsComponent /></ProtectedRoute>} />
                                <Route exact path="professional-details" element={<ProtectedRoute auth={["coach"]} ><CoachProfileProfessionalDetailsComponent /></ProtectedRoute>} />

                                <Route exact path="my-activities" element={<ProtectedRoute auth={["coach"]} ><CoachActivitiesComponent /></ProtectedRoute>} />
                                <Route path="earnings" element={<ProtectedRoute auth={["coach"]} ><CoachEarningComponent /></ProtectedRoute>} />
                                <Route exact path="setting" element={<ProtectedRoute auth={["coach"]} ><CoachSettingComponent /></ProtectedRoute>} />
                                <Route exact path="messages" element={<ProtectedRoute auth={["coach"]} ><CoachMessagesComponent /></ProtectedRoute>} />
                                <Route exact path="my-blogs" element={<ProtectedRoute auth={["coach"]} ><CoachBlogComponent /></ProtectedRoute>} />
                                <Route exact path="my-blogs/create" element={<ProtectedRoute auth={["coach"]} ><CreateBlogComponent /></ProtectedRoute>} />
                                <Route exact path="my-activities/create" element={<ProtectedRoute auth={["coach"]} ><CreateLiveSession /></ProtectedRoute>} />
                                <Route exact path="my-calendar" element={<ProtectedRoute auth={["coach"]} ><CoachCalendarComponent /></ProtectedRoute>} />
                                <Route exact path="booking" element={<ProtectedRoute auth={["coach"]} ><CoachBookingCalendarComponent /></ProtectedRoute>} />
                                <Route path="add-availability" element={<ProtectedRoute auth={["coach"]} ><AddAvailabilitySlot /></ProtectedRoute>} />
                                
                                {/* <Route exact path="/event-whiteBoard/:id" element={<ProtectedRoute auth={["coach"]} ><CanvasWhiteBoardComponent /></ProtectedRoute>} /> */}

                            </Route>
                                <Route path="event-whiteBoard/:id" element={<CanvasWhiteBoardComponent />} />

                            <Route path="submit" element={<SubmitForm />} />
                            <Route path="*" element={<NotFound />} />
                        </Routes>
                        <div style={{zIndex: '+9999'}} className="position-fixed bottom-0 bg-black w-100 text-center fs-12 z-3 p-2">
                            <p className="m-0 text-white">Beta Version</p>
                        </div>
                    </>
            }
        </>
    )
}

export default Root;