import { date } from "yup";
import { api, formDataAPI } from "./axios";

// home page apis
export const getCategoryList = () => api(`coach/category-list`, "GET");
export const getTestimonials = () => api(`testimonials`, "GET");
export const getShortVideos = () => api(`shortVideos`, "GET");
export const getTrendingCoach = () => api(`coach/trending-list`, "GET");

// common apis
export const getCountries = () => api(`countries`, "GET");
export const getStates = (id) => api(`states?country=${id}`, "GET");
export const getLanguages = () => api(`languages`, "GET");

// user-auth apis
export const userSignUp = (data) => api(`users/signup`, "POST", data);
export const userLogin = (data) => api(`users/auth`, "POST", data);
export const forgetPassword = (data) =>
  api(`users/forget-password`, "POST", data);
export const updatePassword = (data) =>
  api(`users/update-password`, "POST", data);

//  user-auth apis
export const userUpdate = (data) => api(`users/update`, "PATCH", data);
export const userImage = (data) => formDataAPI(`users/image`, "PUT", data);

// coach-auth apis
export const coachLinkedin = (code, userType) =>
  api(`auth/getUserCredentials?code=${code}&userType=${userType}`, "GET");
export const coachSignUp = (data) => api(`coach/signup`, "POST", data);
export const coachLogin = (data) => api(`coach/auth`, "POST", data);
export const coachUpdate = (data) => api(`coach/update`, "PATCH", data);
export const coachImage = (data) => formDataAPI(`coach/image`, "PUT", data);
export const coachBGImage = (data) =>
  formDataAPI(`coach/bg-image`, "PATCH", data);

// coach-list apis
export const getCoaches = () => api(`coach/list`, "GET");
export const getCoachById = (id) => api(`coach/${id}`, "GET");

// coach-language apis
export const coachAddLanguage = (data) =>
  api(`coach/add-language`, "POST", data);
export const coachDeleteLanguage = (data) =>
  api(`coach/delete-language`, "POST", data);

// coach-speciality apis
export const coachAddSpeciality = (data) =>
  api(`coach/add-speciality`, "POST", data);
export const coachDeleteSpeciality = (data) =>
  api(`coach/delete-speciality`, "POST", data);
export const searchSpecialtiesByName = (name, page, limit) =>
  api(`specialities?name=${name}&page=${page}&limit=${limit}`, "GET");

// coach-program apis
export const coachAddProgram = (data) => api(`coach/add-program`, "POST", data);
export const coachDeleteProgram = (data) =>
  api(`coach/delete-program`, "POST", data);

// coach-skill apis
export const coachAddSkill = (data) => api(`coach/add-skill`, "POST", data);
export const coachDeleteSkill = (data) =>
  api(`coach/delete-skill`, "POST", data);

// coach-certificate apis
export const coachAddCertificate = (data) =>
  api(`coach/add-certificate`, "POST", data);
export const coachDeleteCertificate = (data) =>
  api(`coach/delete-certificate`, "POST", data);

// coach-experience apis
export const coachAddExperience = (data) =>
  api(`coach/add-experience`, "POST", data);
export const coachDeleteExperience = (data) =>
  api(`coach/delete-experience`, "POST", data);

// coach-education apis
export const coachAddEducation = (data) =>
  api(`coach/add-education`, "POST", data);
export const coachUpdateEducation = (data) =>
  api(`coach/update-education`, "POST", data);
export const coachDeleteEducation = (data) =>
  api(`coach/delete-education`, "POST", data);

// coach-testimonials apis
export const coachAddTestimonial = (data) =>
  formDataAPI(`coach/add-testimonial`, "POST", data);
export const coachDeleteTestimonial = (data) =>
  api(`coach/delete-testimonial`, "POST", data);

// coach-post apis
export const coachAddPost = (data) => formDataAPI(`coach/post`, "POST", data);
export const coachEditPostFormData = (id, data) =>
  formDataAPI(`coach/post/${id}`, "PATCH", data);
export const coachEditPost = (id, data) =>
  api(`coach/post/${id}`, "PATCH", data);
export const coachGetMyPost = (data) => api(`coach/post/get-my`, "GET", data);
export const getPostByCoachId = (id) => api(`coach/post/list/${id}`, "GET");
export const getFilteredCoaches = (data) => api(`coach/list`, "POST", data);
export const coachDeletePost = (id) => api(`coach/post/${id}`, "DELETE");
export const getPosts = (data) =>
  data !== undefined
    ? api(`coach/post/list?search=${data}`, "GET", data)
    : api(`coach/post/list`, "GET", data);

export const coachAddShortVideo = (data) =>
  formDataAPI(`coach/shorts`, "POST", data);

export const coachEditShortVideoData = (id, data) =>
  formDataAPI(`coach/shorts/${id}`, "PATCH", data);
export const coachEditShortVideo = (id, data) =>
  api(`coach/shorts/${id}`, "PATCH", data);
export const coachGetMyShortVideo = (data) =>
  api(`coach/shorts/get-my`, "GET", data);
export const getShortVIdeoByCoachId = (id) =>
  api(`coach/shorts/list/${id}`, "GET");
export const coachDeleteShortVideo = (id) =>
  api(`coach/shorts/${id}`, "DELETE");
export const getShorts = () => api(`coach/shorts/list`, "GET");

export const coachAddCaseStudies = (data) =>
  api(`coach/caseStudies`, "POST", data);
export const coachEditCaseStudy = (id, data) =>
  api(`coach/caseStudies/${id}`, "PATCH", data);
export const coachGetMyCaseStudies = (data) =>
  api(`coach/caseStudies/get-my`, "GET", data);
export const coachGetCaseStudies = (id) =>
  api(`coach/caseStudies/list/${id}`, "GET");
export const coachDeleteCaseStudies = (id) =>
  api(`coach/caseStudies/${id}`, "DELETE");

export const addLikeInShort = (data) =>
  api(`coach/shorts/like-dislike`, "POST", data);
export const addComment = (data) => api(`coach/post/comment`, "POST", data);
export const deletePostComment = (id) =>
  api(`coach/post/comments/${id}`, "DELETE");
export const deleteVideoComment = (id) =>
  api(`coach/shorts/comments/${id}`, "DELETE");
export const addCommentShortVideo = (data) =>
  api(`coach/shorts/comment`, "POST", data);

export const addLike = (data) => api(`coach/post/like-dislike`, "POST", data);
export const getCommentPostById = (id) =>
  api(`coach/post/comments/${id}`, "GET");
export const getCommentShortVideoById = (id) =>
  api(`coach/shorts/comments/${id}`, "GET");

// followers coach
export const followUnfollowCoach = (data) =>
  api(`coach/followers`, "POST", data);
export const getMyFollowings = (data) => api(`coach/followers/list`, "GET");

// coach-tag apis
export const coachTags = (data) => api(`coach/tags`, "POST", data);

// coach-benfit/professions apis
export const coachBenefits = (data) => api(`coach/benefit`, "POST", data);
export const coachProfessions = (data) =>
  api(`coach/professions`, "POST", data);

// auth
export const getLoggedInUser = (data) => api(`auth/me`, "POST", data);

// Filters
export const getMyPosition = (data = "") =>
  api(`coach/positions?name=${data}`, "GET");
export const getMySpecialities = (data = "") =>
  api(`coach/specialities?name=${data}`, "GET");

// stripe
export const addPlan = (data) => api(`stripe`, "POST", data);

// contactus
export const contactus = (data) => api(`contactus`, "POST", data);

// Chat Module API

export const getConversationList = (data) =>
  api(`chat/getConversationList`, "GET");

export const sendMessage = (data) => api(`chat/sendMessage`, "POST", data);
export const messageSeen = (data) => api(`chat/messageSeen`, "POST", data);
export const oldMessages = (data) =>
  api(
    `chat/oldMessages` +
      "?id=" +
      data.id +
      "&limit=" +
      data.limit +
      "&page=" +
      data.page,
    "GET"
  );
export const deleteConversation = (id) =>
  api(`chat/deleteConversation/${id}`, "DELETE");

export const getFilterCoachOfUserInterest = (query) =>
  api(`coach/filterlist?search=${query}`, `GET`);

export const searchCoachWithText = (query) =>
  api(`coach/search?search=${query}`, `GET`);

export const getAllBlogs = (data) => api(`blogs/all-list`, "POST", data);
export const getUserBlogs = () => api(`blogs/user-blog`, "POST");
export const publishProfile = () => api("coach/publishProfile", "POST");

// Session Api
export const coachAllSession = (data) =>
  api(`coach/getAllSession`, "GET", data);
export const coachSessionList = (data) =>
  api(`coach/getCoachSessionList`, "POST", data);
export const getSessionUsers = (data) =>
  api("coach/interestedUsers", "POST", data);

export const createSession = (data) =>
  formDataAPI(`coach/storeSession`, "POST", data);
export const editSession = (data) =>
  formDataAPI(`coach/editSession`, "POST", data);

export const getFreeSession = () =>
  formDataAPI(`coach/getFreeSessionList`, "POST");
export const addSessionInterest = (data) =>
  api(`coach/addSessionInterest`, "POST", data);
export const changeSessionStatus = (data) =>
  api(`coach/changeSessionStatus`, "POST", data);

// Blog comments
export const addBlogComment = (data) => api(`blogs/add-comment`, "POST", data);
export const allBlogComment = (id) => api(`blogs/${id}/all-comments`, "GET");
export const deleteBlogComment = (data) =>
  api(`blogs/delete-comment`, "POST", data);
// export const addBlogComment = (data) => api(`blogs/add-comment`, "POST", data);

export const singleBlogDetails = (id) => api(`blogs/${id}/details`, "GET");
export const blogLikeDislike = (data) => api(`blogs/like`, "POST", data);
export const shareBlog = (id) => api(`blogs/${id}/share`, "GET");
export const allCategories = () => api(`blogs/categories`, "GET");
export const allAuthor = () => api(`blogs/authors`, "GET");

export const shortListHome = (data) =>
  api(
    `coach/shorts/list?limit=${data.pageLimit}&page=${data.pageNumber}`,
    "GET"
  );

export const shortListComment = (id) =>
  api(`coach/shorts/comments/${id}`, "GET");

export const createBlog = (data) =>
  formDataAPI(`coach/blogs/create`, "POST", data);

export const blogList = () => api(`coach/blogs/list`, "GET");

export const editBlog = (data) => formDataAPI(`coach/blogs/edit`, "POST", data);

export const getSocialPosts = (data) =>
  data !== undefined
    ? api(`coach/post/list?${data}`, "GET", data)
    : api(`coach/post/list`, "GET", data);

export const coachBlogList = (id) => api(`coach/blogs/${id}/get`, "GET");
export const getSingleCoachBlogList = (id) =>
  api(`coach/blogs/${id}/published-blogs`, "GET");

export const userActivityList = (id) => api(`users/activities/list`, "GET");

export const myContacts = () => api("coach/followers/coach-list", "GET");
export const sessionForUsers = () => api("users/livesessions/sessions", "POST");

export const singlePostDetails = (id) => api(`coach/post/${id}/details`, "GET");

export const singleShortDetails = (id) =>
  api(`coach/shorts/${id}/details`, "GET");

// transaction API
export const createNewTransaction = (data) =>
  api("transactions/create", "POST", data);
export const myTransactions = () => api("transactions/my-transaction", "GET");
export const myEarnings = () => api("transactions/my-earnings", "GET");
export const addNewBankDetails = (data) =>
  api("transactions/add-bank-account", "POST", data);
export const getBankAccountList = () =>
  api("transactions/bank-account-list", "GET");

// Coach Program API

export const getCoachBookedSlots = () =>
  api("coach/program/coach-scheduled-list", "GET");
export const getUsersProgramsList = () => {
  api("coach/program/user-session-list", "GET");
};
export const getMyCoachList = () => {
  api("coach/program/users-coach-list", "GET");
};
export const myClientsLists = () => {
  api("coach/program/coach-clients-list", "GET");
};
export const registerUsersInProgram = (data) =>{
  api("coach/program/register-user", "POST", data)
}
export const addAvailability = (data) =>{
  api("coach/program/create-slot", "POST", data)
}